<template>
<div>
  <br>
  <div class="row justify-content-center" v-if="testigo == 0">
    <div class="col-md-11 col-11" > 
       <div class="form-group mt-3">
            <div class="col-md-4 text-left">
                <div class="input-group" style="margin-left: -15px;">
                    <input type="text" v-model="buscar" class="form-control input-nav-search" placeholder="Buscar"> 
                </div>
            </div>
        </div>
        <div class="table-responsive mt-3">
            <table class="table table-hover table-striped mt-3" >
                <thead class="bg-primary text-white">
                <tr>
                    <!-- <th scope="col"></th> --> 
                    <!-- <th scope="col" style="font-size:17px;" class="text-center">Líder</th> -->
                    <!-- <th scope="col" style="font-size:17px;" class="text-center">Canal</th> -->
                    <th scope="col" style="font-size:17px;" class="text-center">Proyecto</th>
                    <!-- <th scope="col" style="font-size:17px;" class="text-center"></th>  -->
                    <th scope="col" style="font-size:17px;" class="text-center">Fecha de recepción</th>
                    <th scope="col" style="font-size:17px;" class="text-center">Estatus</th>
                    <th scope="col" style="font-size:17px;" class="text-center">Fecha de confirmación</th> 
                    <!-- <th scope="col" style="font-size:17px;" class="text-center">Testigo recepción</th>  -->
                </tr>
                </thead>
                <tbody>
                <tr v-for="list in listado" :key="list.id"> 
                    <!-- <td class="text-center">
                      {{list.usuario_responsable}}
                    </td>  -->
                    <!-- <td class="text-center">{{list.area_canal}}</td> -->
                    <td class="text-center">
                      <h6 style="color:#3ABF30; cursor: pointer;" ><u @click="verPromo(list)">{{list.proyecto}}</u> 
                       <i v-if="list.incidencia" style="color:#f2b711; cursor: pointer;" class="ml-3 fas fa-exclamation-triangle" @click="verIncidencia(list)"></i>
                      </h6>
                    </td> 
                    <!-- <td class="text-center"> 
                       <h6 style="color:#3ABF30; cursor: pointer;" @click="verPromo(list)"><u>Ver</u></h6> 
                    </td>  -->
                    <td class="text-center">{{list.fecha_entrega | fechaA}}</td> 
                    <td class="text-center"> 
                        <template v-if="list.estatus == 0">
                            <p style="color:#00A9E0;">Cancelado</p> 
                        </template>
                        <template v-if="list.estatus == 1">
                            <p style="color:#3ABF30;">Confirmado</p> 
                        </template>
                        <template v-if="list.estatus == 2">
                            <p style="color:#039cf3;">Por Confirmar</p> 
                        </template>      
                    </td> 
                    <td class="text-center"> 
                      <template  v-if="list.estatus == 1">{{list.updated_at | fechaA}}</template>
                    </td>  
                    <!-- <td class="text-center"> 
                      <template v-if="list.estatus == 1">
                        <u style="color:#3ABF30; cursor: pointer;" v-if="list.testigo" @click="verTest(list)">Ver</u>
                        <u style="color:#3ABF30; cursor: pointer;" v-else @click="subirTest(list.id)">Subir testigo</u>
                       </template>
                    </td>    -->
                </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-12" v-if="listado.length == 0" style="background: #FCFCFC; box-shadow: inset 0 0 10px 0 #CDCDCD;">
            <center>
                <br>
               <h4 style="color:#098FD6; marg">Por el momento no cuentas con proyectos.</h4> 
                <div class="col-md-4" v-if="listado.length == 0">
                    <hr style="border-top: 5px solid #CAF5A8 !important;  opacity :2;">
                </div>
            </center>
        </div>
        <br><br> 
    </div> 
  </div> 


    <div class="row justify-content-center " v-else> 
    <div class="col-md-1 col-12" v-if="!isMobile"></div>
    <div class="col-md-11 col-12" >
      <!-- <u class="ml-4" v-if="isMobile" :style="isMobile ? 'cursor: pointer;  font-size:20px; color:#3ABF30;' : 'cursor: pointer;  margin-left: 975px; font-size:20px; color:#3ABF30;'" @click="regresaList()">Regresar</u> -->
      <h1 :class="!isMobile ? 'mt-3' : ' ml-4'" :style="isMobile ? 'font-size: 20px;' : 'font-size: 26px;'">Proyecto:<b> {{obj.proyecto}}</b></h1>
      <u :class="isMobile ? 'ml-4 mt-3' : ' mt-5'" :style="isMobile ? 'font-size:15px; cursor: pointer;  color:#3ABF30;' : 'cursor: pointer; font-size:18px; color:#3ABF30;'" @click="regresaList()">Regresar</u>
         <div :class=" isMobile ? 'row justify-content-center ' : 'row justify-content-center mt-5'">
           <div class="col-md-11 col-12 mt-3" >
              <b-alert variant="success" show style="color:#000;  background: #D5E4C5; border-color:#D5E4C5; font-size:15px">
                 <div class="row"> 
                   <div class="col-md-6 col-11" >
                     <b>Manager Regional:</b> {{obj.usuario_responsable}} <br> 
                     <b>Plaza(s):</b> {{obj.plaza}}
                   </div>
                   <div class="col-md-2 col-11" >
                     <b>Región:</b> {{obj.region}} <br> 
                     <b>Fecha envío:</b>  <template v-if="obj.fecha_entrega != '0000-00-00'">{{obj.fecha_entrega}}  </template>
                   </div>
                   <div class="col-md-2 col-11" >
                     <b>Bodega:</b> {{obj.bodega}}
                   </div>
                   <div class="col-md-2 col-11" >
                     <b>Territorio:</b> {{obj.territorio}}
                   </div>
                 </div>    
              </b-alert>
              <br> 
           </div>
           <div class="col-md-1"></div>
           <div class="col-md-10 col-11 row justify-content-center" >  
                  <div  v-for="det  in arrayPromo" :key="det.id"  :class="isMobile ? 'col-md-6 mt-1'  : 'col-md-4'">
                    <br v-if="!isMobile">
                    <center>
                      <h4>{{!det.nombre ? det.nombre2 : det.nombre}}</h4> 
                      <b style="color:#00A9E0; cursor: pointer;" >Cantidad {{det.cantidad}}</b><br>
                      <!-- <span style="color: #818181;" v-if="det.nombre">Disponible {{det.existente}}</span>    -->
                      <br><br> 
                      <img v-if="det.nombre" :src="'https://controlpromocionales.asistentemovistar.com.mx/imgs/'+ det.foto" alt="Image" :width="isMobile ? 275 : 270"> 
                      <img v-else :src="'https://controlpromocionales.asistentemovistar.com.mx/imgs/' + det.foto" alt="Image" :width="isMobile ? 275 : 270"> 
                      <br><br>
                      <hr style="border-top: 5px solid #5BC500 !important;  opacity :2; width: 50%;"> 
                    </center> 
                </div>   
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-10 col-11 text-center" >  
                <br> <br><br>
                <b style="font-size:20px">Estatus:
                      <template v-if="obj.estatus == 0">
                        <span style="color:#00A9E0;">Cancelado</span> 
                      </template>
                      <template v-if="obj.estatus == 1">
                        <span style="color:#00A9E0;">Confirmado</span> 
                      </template>
                      <template v-if="obj.estatus == 2">
                        <span style="color:#00A9E0;">Por Confirmar</span> 
                      </template>
                </b> 
                <br><br> 
                <div class="row justify-content-center mt-2 " >  
                  <div class="col-md-2 col-6 text-center" v-if="obj.testigo">
                      <button  @click="verTest(obj)" class="btn btn-block btn-light float-center  mt-3" type="button"
                        style="color:#fff; border-radius: 26px;  background:#5BC500; border-color:#5BC500; font-size:14px;">
                          <b> Ver Testigo</b>
                      </button> 
                   </div>
                   <div class="col-md-12" v-if="isMobile"></div>
                   <div class="col-md-2 col-6 text-center" v-if="obj.estatus == 2">
                     <button  @click="subirTest(obj.id)"  class="btn btn-block btn-light float-center  mt-3" type="button"
                        style="color:#fff; border-radius: 26px; background:#5BC500; border-color:#5BC500; font-size:14px;">
                          <b> Confirmar recepción </b>
                      </button> 
                   </div>
                   <div class="col-md-12" v-if="isMobile"></div>
                   <div class="col-md-2 col-6 text-center" v-if="obj.estatus == 2">
                      <button  @click="agregarInci(obj)" class="btn btn-block btn-light float-center  mt-3" type="button"
                        style="color:#fff; border-radius: 26px; background:#E9426D; border-color:#E9426D; font-size:14px;">
                          <b> Reportar incidencia</b>
                      </button> 
                   </div>
                </div>  
                <br><br>
            </div> 
              <div class="col-md-1"></div>
         </div>
    </div> 
   </div>  

    <b-modal id="promos" title="Testigo Recepción" hide-footer size="lg" > 
      <div class="row justify-content-center">  
        <div class="col-7">
            <!-- <b>Testigo fotografico</b> -->
            <br>
            <div v-for="tes  in arrayTest" :key="tes.id" >
              <center>
               <img crossorigin="anonymous" :src="$api.baseUriFiles+'control_promocional/' + tes" alt="Image"  :width="isMobile ? 250 : 400">
               <hr style="border-top: 5px solid #5BC500 !important;  opacity :2; width: 50%;"> 
              </center>
            </div>
        </div>   
         <div class="col-12"><br><br></div>
      </div>  
    </b-modal>

    <b-modal id="cantid" title="Modificar Cantidad" hide-footer   >
      <div class="row justify-content-center">
        <div  class="col-md-10 text-center" >
            <label for="" >Introduce la cantidad entregada</label>
            <b-form-input class="mt-2" placeholder="Cantidad" v-model="cantidad"   type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>  
            <br>
            <center>
                <button  class="btn btn-primary float-center btn-sm" type="button" style="border-radius: 26px; width: 30%;" @click="registraCantidad()">
                  Editar
                </button>
            </center>
        </div> 
      </div>
    </b-modal>

    <b-modal id="incide" title="Reportar Incidencia" hide-footer   >
      <div class="row justify-content-center">
        <div  class="col-md-10 " > 
           <b-form @submit="registraIncidencia">        
            <!-- <b-form-file 
               class="form-control" 
                @change="fileSelected"
                browse-text="Elige"
                placeholder="Selecciona un archivo"
                drop-placeholder="Selecciona un archivo"
            ></b-form-file>   --> 
            <label for="" >Descripción:</label>
            <b-form-textarea
              id="textarea" 
              placeholder="Incidencia..."
              rows="3"
              max-rows="6"
              v-model="incidencia"
            ></b-form-textarea>
            <small v-if="errors.includes('incidencia')" class="text-danger mt-2 text-left">
                <i class="fas fa-exclamation-circle"></i>
                Este campo es obligatorio
            </small>  
            <br>
           <b-form-group label="Subir testigo">
                <b-form-file v-model="testigo_incidencia" id="file-default2"  placeholder="Selecciona un archivo"  drop-placeholder="Selecciona un archivo" browse-text="Seleccionar" class="text-left"></b-form-file>  
            </b-form-group> 
            <!-- <b-form-input class="mt-2" placeholder="Cantidad" v-model="cantidad"   type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"/>   -->
            <br>
            <center>
                <button  class="btn btn-primary float-center btn-sm" type="submit" style="border-radius: 26px; width: 30%;">
                  Enviar <b-spinner small v-if="loaderTest"></b-spinner>
                </button>
            </center>
         </b-form>  
        </div> 
      </div>
    </b-modal>

    <b-modal id="verinci" title="Incidencia Reportada" hide-footer   >
      <div class="row justify-content-center">
        <div  class="col-md-10 " >          
            <p><b>Descripción:</b> {{obj.detalle_incidencia}}</p> 
            <b class="mt-3">Testigo:</b>
             <center>
               <img crossorigin="anonymous" :src="$api.baseUriFiles+'control_promocional/' + obj.incidencia" alt="Image"  :width="isMobile ? 250 : 400">
               <hr style="border-top: 5px solid #5BC500 !important;  opacity :2; width: 50%;"> 
             </center>
            <br>
            <center>
                <button  class="btn btn-primary float-center btn-sm" type="button" style="border-radius: 26px; width: 30%;" @click="cerrarInci()">
                 Cerrar
                </button>
            </center>  
        </div> 
      </div>
    </b-modal>
    
    <b-modal id="subirTest" title="Confirmación de recepción. Subir testigo" hide-footer  size="lg">
      <div class="row justify-content-center">
        <div  class="col-md-10 text-center" >
          <br>
            <file-pond  ref="pond" style="color:#fff;"
                        :label-idle="isMobile ? 'Clic aquí para subir tus fotos. Máximo 3 imágenes.' : 'Arrastra o da clic aquí para subir tus fotos. Máximo 3 imagen.'"
                        v-bind:allow-multiple="true"
                        accepted-file-types="image/*">

                <template v-slot:label></template>

            </file-pond> 
           <small v-if="errors.includes('archivo')" class="text-danger mt-2 text-left">
                <i class="fas fa-exclamation-circle"></i>
                Este campo es obligatorio
            </small> 
               <small v-if="errors.includes('invalido')" class="text-danger mt-2 text-left">
                <i class="fas fa-exclamation-circle"></i>
                Formato no válido
            </small>   
           <br>
            <!-- <label for="" >Sube un testigo fotografico del Promocional</label> 
            <v-file-input class="mt-4" label="Selecciona archivo" v-model="archivo"></v-file-input> 
            <br> -->
            <div class="row justify-content-center">
                <div  class="col-md-7" ></div>
                <div  class="col-md-5" >
                  <button  class="btn btn-secondary  btn-sm" type="button" style="border-radius: 10px; width: 40%;" @click="cerrarTest()">
                    Cerrar
                  </button>
                  <button  class="btn btn-primary btn-sm" type="button" style="border-radius: 10px; width: 40%; margin-left: 11px;" @click="guardaTestigo()">
                    Enviar <b-spinner small v-if="loaderTest"></b-spinner>
                  </button>
              </div>
            </div>
        </div> 
      </div>
    </b-modal>

    
    <div class="modal fade bd-example-modal"  role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true" id="materialCamp">
        <div class="modal-dialog modal  modal-dialog-centered" >

            <div class="modal-content"  style="  background-image: url('https://controlpromocionales.asistentemovistar.com.mx/imgs/popup2.png');  background-size: cover;   background-repeat: no-repeat; margin-top:-20%;">
              <br><br> 
                <b-row class="justify-content-center">
                  <!-- <b-col cols="10">
                      <a style="color: #fff; cursor: pointer;" data-dismiss="modal" aria-label="Close" class="m-3 text-right">
                          <i class="fas fa-arrow-left"></i>
                          Cerrar Ventana
                      </a>
                      <br> 
                  </b-col> -->
                  </b-row>
                    <div class="modal-body" >
                      <div class="row justify-content-center">
                          <div class="col-12 text-center" style="color:#fff;" >
                            <img src="https://controlpromocionales.asistentemovistar.com.mx/imgs/listo.png"  class="img-fluid" width="120">
                            <template v-if="modal_val == 0">
                              <br><br>
                                <h5><b>Testigo(s) enviado(s) exitosamente: <br><br>  Proyecto: {{obj.proyecto}}</b></h5>
                                <br>
                                <!-- <h5>En breve te contactaremos.</h5> -->
                                <br> 
                             </template>
                             <template v-if="modal_val == 1">
                                <br><br>
                                <h5><b>Incidencia enviada: <br><br>  Proyecto: {{obj.proyecto}}</b></h5>
                                <br>
                                <h5>En breve te contactaremos.</h5>
                                <br> 
                             </template>
                              <br>  
                              <button  class="btn btn-primary float-center btn-sm" type="button" style="font-size:16px; color:#2b82c5;  background:#fff; border-color:#fff; width: 20%;" 
                                @click="cerrarModal()">
                                  OK
                              </button> 
                              <template v-if="!isMobile"><br><br><br></template>
                          </div>
                        </div>
                    </div>
            </div>
        </div>
    </div> 
</div>
</template>
<script>
import Swal from 'sweetalert2' 
import moment  from 'moment'; 
import vueFilePond from 'vue-filepond'; 
const FilePond = vueFilePond();
import $ from 'jquery';  
import 'bootstrap';
export default {
 name:"Listado", 
     components: { 
      FilePond 
    },
  data() {
    return {
        arrayList:[],
        buscar:"",
        arrayPromo:[],
        motivo:"",
        idpromo:0,
        cantidad:"",
        fecha:"",
        obj:{},
        isMobile: this.$mobile(),
        arrayComent:[],
        comentario_seccion:"",
        loaderComent:false,
        testigo:0,
        archivo:null,
        loaderTest:false,
        arrayPros:[],
        idincidencia:'',
        incidencia:'',
        proyecto:{},
        testigo_incidencia:'',
        modal_val:0,
        errors:[],
        arrayTest:[]
    }
  },
    filters: {
        fechaA:function(value) {
            if (value) {
                moment.locale('es')
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        fechaFormato:function(value) {
            if (value) {
                moment.locale('es')
                return moment(String(value)).format('YYYY-MM-DD  LTS')
            }
        },
    },
  computed:{
    user() {
        return this.$store.getters.getInfoUser;
    },
    listado(){
        
            if (this.buscar == "") {          
                return this.arrayList;
            }else{
                return   this.arrayList.filter(item => {
                        return  item.tipo_registro?.toLowerCase().includes(this.buscar.toLowerCase()) ||
                                item.responsable?.toLowerCase().includes(this.buscar.toLowerCase()) ||
                                item.canal?.toLowerCase().includes(this.buscar.toLowerCase()) ||
                                item.proyecto?.toLowerCase().includes(this.buscar.toLowerCase()) || 
                                item.created_at?.toLowerCase().includes(this.buscar.toLowerCase()) 
                        ;
                });
            }  
        

    },
  },
  methods: {  
    verIncidencia(item){
      this.obj = item; 
      this.$bvModal.show('verinci');
    },
    cerrarModal(){
        $('#materialCamp').modal("hide");  
    },
    fileSelectedN4(event) {
       this.testigo_incidencia = event.target.files[0];
    },
    regresaList(){
      this.$bus.$emit("menuMuestra")
      this.testigo = 0;
      this.listadoAct();
    },
    traePromo(){ 
        var url= 'control_promo/promocionales';
        this.$api.get(url).then(
                ({data}) => {
                    this.arrayPros = data.data.list;
                }
            );
        
    },
    verTest(item){
      this.obj = item;
      this.arrayTest = this.obj.testigo.split(','); 
      this.$bvModal.show('promos');
    } ,
    subirTest(id){
      this.errors = [];
      this.idpromo = id;
      this.$bvModal.show('subirTest');
    },
    cambiaTab(id){
      this.tab = id;
    },  
    listadoAct(){
        var url= 'control_promo/index?mrt='+ this.user.mrt + '&perfil=Lider';
            this.$api.get(url).then(
                    ({data}) => {
                        this.arrayList = data.data.list; 
                    }
                );
    },
    verPromo(item){
      this.obj = item;
        var url= 'control_promo/promos?id='+ item.id;
            this.$api.get(url).then(
                    ({data}) => { 
                        this.arrayPromo = data.data.promos;
                        this.arrayComent = data.data.comentario;
                         this.testigo = 1;
                          this.$bus.$emit("menuOculta")
                        //  this.$bvModal.show('promos');
                    }
                );
    },
    cambiarEstado(id){
            Swal.fire({
                title: '¿Estás seguro de cambiar el estatus?',
                showCancelButton: true,
                confirmButtonColor: '#3ABF30',
                cancelButtonColor: '#187abe',
                confirmButtonText: 'Aceptar',
                cancelButtonText:'Cancelar',
                }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed)
                {
                    var url= 'control_promo/estatus?id='+ id + '&est='+ this.motivo;
                    this.$api.get(url).then(
                            ({data}) => {
                                console.log(data); 
                                this.listadoAct(); 
                                Swal.fire(
                                    "List@",
                                    "Promocional ha sido cambiado en estatus exitosamente.",
                                    'success'
                                    )
                            }
                        );             
                }
            })
    },
    cambiarCant(item){
        this.idpromo = item.id;
        this.cantidad = ""; 
        this.$bvModal.show('cantid'); 
    },
    registraCantidad(){
        if (!this.cantidad) {
            this.$swal("Aviso", "Datos requeridos.", "warning")
        } else {
            var url= 'control_promo/cantidad?id='+ this.idpromo + '&cantidad='+ this.cantidad;
            this.$api.get(url).then(
                    ({data}) => {
                        console.log(data); 
                        this.$bvModal.hide('cantid');
                        this.idpromo = 0;
                        this.cantidad = "";
                        this.listadoAct(); 
                        Swal.fire(
                            "List@",
                            "Cantidad ha sido cambiada exitosamente.",
                            'success'
                            )
                    }
                );       
        }
    },
    cambiarFecha(id){
         var url= 'control_promo/fecha?id='+ id + '&fecha='+ this.fecha;
            this.$api.get(url).then(
                    ({data}) => {
                        console.log(data);  
                        this.fecha = "";
                        this.listadoAct(); 
                        Swal.fire(
                            "List@",
                            "Fecha ha sido cambiada exitosamente.",
                            'success'
                            )
                    }
                );      
    },
    enviarComentario(){
           if (!this.comentario_seccion)
            {
              Swal.fire({
                      icon: 'error',
                      title: 'Aviso',
                      text: 'Comentario es requerido' 
                      }) 
            }else{
                this.loaderComent = true;        
                
                let model = this.prepareModelComent()
                    this.$api.post('control_promo/comentarios', model).then( response => {
                            this.comentario_seccion = "";  
                            this.loaderComent = false;  
                            this.arrayComent = response.data.data.comentario; 
                            Swal.fire(
                            "List@",
                            "Comentario enviado exitosamente.",
                            'success'
                            )                   
                        }).catch(error => {
                            this.loaderComent = false;
                            console.log(error);
                        });
            }
    },
    prepareModelComent(){
      let model = new FormData(); 
        model.append("comentario", this.comentario_seccion) 
        model.append("mrt", this.user.mrt)
        model.append("idpromo", this.obj.id)
        model.append("envia", this.user.nombre)
        model.append("perfil", "Líder")
        return model;
    },
    guardaTestigo(){
      let files = this.$refs.pond.getFiles();
         var imagen = "";
          files.forEach((file) => {
              imagen = file.file;
          });

          console.log(imagen);

      if (files.length == 0)
        {
            this.errors.push('archivo');   
        }else{
          this.loaderTest = true;
           this.modal_val = 0;
          let model = this.prepareModelTestigo()
            this.$api.post('control_promo/testigo', model).then( response => {
                  
                   if (response.data.data.valida == 1) {
                      this.errors.push('invalido');   
                      this.loaderTest = false;
                   }else{
                    this.obj = response.data.data.up;
                    this.archivo = null;  
                    console.log(response);
                    this.loaderTest = false;
                      this.listadoAct(); 
                    this.$bvModal.hide('subirTest');
                    // this.verPromo(this.obj);
                    // Swal.fire(
                    // "List@",
                    // "Testigo se envió exitosamente.",
                    // 'success'
                    // )       
                  // if (this.isMobile) {
                        $('#materialCamp').modal("show");
                      // $('#materialCamp2').modal("show");
                      //   Swal.fire(
                      //   "List@",
                      //   "Testigo enviado exitosamente.",
                      //   'success'
                      //   )
                    // }else{
                    //   Swal.fire({ 
                    //     title: 'Testigo enviado exitosamente <br><br> Proyecto:' + this.obj.proyecto + '<br><br>', 
                    //     imageUrl: 'https://controlpromocionales.asistentemovistar.com.mx/imgs/listo.png',
                    //     imageWidth: 200,
                    //     imageHeight: 100,
                    //     width: 800,
                    //     padding: '1.5rem',
                    //     color: '#fff',
                    //     background: 'transparent url(/imgs/fondo.png)  no-repeat', 
                    //   })            
                    // }
                   }
                }).catch(error => { 
                    console.log(error);
                });
        }
    },
    prepareModelTestigo(){
      let model = new FormData(); 
        model.append("idpromo", this.idpromo) 
        // model.append("archivo", this.archivo) 

        let files = this.$refs.pond.getFiles();
            files.forEach((file) => {
            model.append("archivo[]", file.file);
        });

        return model;
    },
    cerrarInci(){
     this.$bvModal.hide('verinci');
    },
    cerrarTest(){ 
      this.$refs.pond.removeFiles();
      this.$bvModal.hide('subirTest');
      this.$bvModal.hide('verinci');
    },
    agregarInci(item){
      this.errors = [];
      this.proyecto = item;
      this.idincidencia = item.id;
       this.$bvModal.show('incide');
    },
    registraIncidencia(e){
      e.preventDefault();
      if (!this.incidencia) {
             this.errors.push('incidencia');   
        } else { 
          this.modal_val = 1;
          this.loaderTest = true;
          let model = this.prepareModelIncidencia()
         this.$api.post('control_promo/incidencia', model).then( response => {
                 console.log(response); 
                     this.$bvModal.hide('incide');
                        this.idincidencia = '';
                        this.incidencia = "";
                        this.loaderTest = false;
                        this.listadoAct(); 
                        // Swal.fire(
                        //     "List@",
                        //     "Incidencia enviada. En breve te contactaremos.",
                        //     'success'
                        //     )
                  //  if (this.isMobile) {
                      // this.$bvModal.hide('incide');
                      $('#materialCamp').modal("show");
                    // }else{
                    //  Swal.fire({ 
                    //     title: 'Incidencia enviada: <br><br> Proyecto: ' + this.proyecto.proyecto + '<br> En breve te contactaremos. <br>', 
                    //     imageUrl: 'https://controlpromocionales.asistentemovistar.com.mx/imgs/listo.png',
                    //     imageWidth: 200,
                    //     imageHeight: 100,
                    //     width: 800,
                    //     padding: '1.5rem',
                    //     color: '#fff',
                    //     background: 'transparent url(/imgs/fondo.png)  no-repeat', 
                    //     })
                    // } 

                }).catch(error => { 
                    console.log(error);
                }); 
            }
        },
        prepareModelIncidencia(){
          let model = new FormData(); 
            model.append("id",  this.idincidencia ) 
            model.append("incidencia", this.incidencia)  
            model.append("testigo", this.testigo_incidencia)  

            return model;
        } 
  },
  mounted(){
    this.listadoAct(); 
    this.traePromo();
  }
}

</script>
<style>
  #hover:hover{
    background:#B2DAFF;
  }

  #tabsCapturaPop .active {
    background: none;
    color: var(--color-primary);
    transition: all 300ms ease;
  }

  #tabsCapturaPop a {
    background: none;
    color: #85848A;
    transition: all 300ms ease;
  }
  #tabsCapturaPop a i {
    transition: all 300ms ease;
    animation-duration: 0.3s;
    font-size: 10px;
  }

  #tabsCapturaPop #capturaBtnReporte .active {
    background: #00a9e0;
    color: white;
  }

  #tabsCapturaPop #capturaBtnReporte {
    margin: 0 20px 0 20px !important;
  }
  
  .input-nav-search::placeholder {
  color: #a8a8a8;
  font-weight: lighter;
  font-family: TelefonicaLight, serif;
  font-size: 15px;
}


  #pagi a {
    background: none;
    color: #85848A;
    transition: all 300ms ease;
  }


.filepond--drop-label {
     background-color: #039cf3 !important;
    box-shadow: 0px 10px 10px -6px black;
    cursor: pointer;
    color: #fff;
}

.flexbox {
  align-items: center;
  display: flex;
  height: 75px;
  justify-content: center;
  width: auto;
}

.flexbox2 {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  width: auto;
}


#global {
	height: 140px;
	width: 100%;
	border: 1px solid #FAFAFA;
	background: #FAFAFA;
	overflow-y: scroll;
}
#mensajes {
	height: auto;
}

.filepond--drop-label {
     background-color: #039cf3 !important;
    box-shadow: 0px 10px 10px -6px black;
    cursor: pointer;
    color: #fff;
}

#filepond--drop-label-96imw404a{
    font-weight: bold; color: #000;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 0vw;
    height: 0vw;
    background-color: transparent;
} 
</style>
